#logo {
  float: left;
  padding-top: 20px;
  padding-left: 20px;
  /* width: 200px; */
}

#text {
  color: #054D74;
  /* width: 575px; */
  /* width: 30%; */
  /* height: 265px; */
  /* height: 35%; */
  /* background-color: brown; */
  /* margin-top: auto;
  margin-bottom: auto; */
  margin: auto;
  text-align: left;
}

#forgotPassword {
  color: #054D74;
  font-weight: bold;
  font-size: 39.8px;
  text-align: center;
  /* margin-top: 0px; */
  /* float: left; */
  /* font-size: 57.7px; */
  /* font-size: 330%; */
  /* font-size: 2.7vw; */
}

#enterEmailText {
  color: black;
  float: middle;
  /* font-size: 27px; */
  font-size: 18px;
  text-align: left;
}

.forgotPasswordPage {
  top: 100px;
  padding: 7px;
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 22px;
  overflow: hidden;
}

#enterYourEmail {
  padding-left: 1.1vw;
  margin-top: 25px;
  /* width: 569px; */
  width: 94%;
  /* height: 30px; */
  height: 2.4vw;
  /* height: %; */
  border-radius: 5px;
  border-width: 1px;
  /* font-size: 18px; */
  font-size: 1.3vw;
  /* padding-left: 15px; */
}

#send {
  padding-bottom: 0;
  margin-bottom: 0;
  height: 40px;
  padding-bottom: 5px;
}

#inputFields {
  float: top;
}

#toLogin {
  text-align: right;
  float: right;
  /* padding-left: 0; */
  padding-top: 0;
  padding-right: 0;
  margin-right: 0;
}

#resendEmail {
  padding-left: 8px;
}

#resendEmailGrid {
  font-size: 15px;
  text-align: left;
}

#resetPassword {
  padding-left: 8px;
}

#resetPasswordGrid {
  font-size: 15px;
  text-align: left;
}

#enterYourPassword {
  margin-top: 25px;
  /* width: 569px; */
  /* height: 30px; */
  height: 2.4vw;
  border-radius: 5px;
  border-width: 1px;
  /* font-size: 18px; */
  font-size: 1.3vw;
  /* padding-left: 15px; */
  padding-left: 1.1vw;
  width: 94.2%;
  /* width: 28.3vw; */
}

#resetYourPassword {
  width: 29.5vw;
  margin-top: 0px;
  margin-bottom: 0px;
  float: left;
  /* font-size: 57.7px; */
  font-size: 3vw;
  text-align: center;
}