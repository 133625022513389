.taskBar {
  height: 10%;
  width: 100%;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0px 1px 5px #c5c5c5;
  white-space: nowrap;
}

#logo {
  max-width: 100%;
  min-width: 150px;
  background-color: white;
}

a {
  padding-left: 8%;
  padding-right: 10%;
  color: grey;
  text-decoration: none;
  font-size: 1.2em;
}

a:hover {
  text-decoration: underline;
}
