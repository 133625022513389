.loginPage {
  top: 100px;
  padding: 7px;
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 22px;
  overflow: hidden;
}

#loginLogo {
  width: 80%;
}

p {
  font-size: 14px;
}
